<template>
  <div class="outer mobile-date-picker">
    <div class="inner">
      <!-- <div class="calendar-title">When will you be there?</div> -->
      <div @click="close()" class="chevron chevron-left"> 
         <v-icon>mdi-chevron-left</v-icon>
      </div>
       <div class="calendar-title">Select Dates</div>
      <div class="disclaimer">Prices are subject to change with dates</div>
      <div class="divider"></div>

      <v-date-picker
        style="border: none;"
        v-model="compDateModel"
        no-title
        class="date-picker"
        :min="currentDate"
        range
      ></v-date-picker>
      <div class="divider"></div>

      <div class="action-bar">
        <v-btn class="cancel-btn" @click="clear()"> Clear date </v-btn>
        <v-btn
          color="primary"
          :disabled="disabledProceed"
          class="proceed-btn"
          @click="
            () => {
              apply(dateModel);
            }
          "
        >
          Save
        </v-btn>
      </div>
    </div>
    <GuestsPicker
      :close="
        () => {
          showGuestsPicker = false;
          $router.go(-1);
        }
      "
      v-if="showGuestsPicker"
    />
  </div>
</template>

<script>
import moment from 'moment';
import GuestsPicker from './AddGuestsPicker.vue';
export default {
  name: 'DateRangePicker',
  props: [ 'close', 'checkInDate', 'checkOutDate', 'setDateRange', 'selectedCity' ],
  components: {
    GuestsPicker
  },
  data() {
    return {
      dateModel: null,
      currentDate: moment().format('yyyy-MM-DD'),
      showGuestsPicker: false,
    };
  },
  mounted() {
    this.dateModel = [
      this.checkInDate.format('yyyy-MM-DD'),
      this.checkOutDate.format('yyyy-MM-DD'),
    ];
    window.addEventListener('hashchange', this.hashChangeHandler, false);
  },
  computed: {
    disabledProceed: {
      get() {
        return !this.dateModel.length > 1;
      },
    },
    compDateModel: {
      get() {
        return this.dateModel;
      },
      set(val) {
        if (val.length == 1) {
          this.dateModel = val;
        } else {
          if (val.length > 1) {
            if (moment(val[0]) > moment(val[1])) {
              [ val[0], val[1] ] = [ val[1], val[0] ];
            }
          }
          if (val[0] !== val[1]) {
            this.dateModel = val;
          }
        }
      },
    },
    isLanding() {
      return this.$route.path === '/';
    },
  },

  methods: {
    clear() {
      this.dateModel = [];
    },
    apply(value) {
      this.setDateRange(value);
      if (this.isLanding && this.dateModel.length != 0) {
        this.showGuestsPicker = true;
        this.openGuestPicker();
        // this.$router.push({
        //   path: '/mobilefeed/top/0',
        //   // query: { ...this.$route.query, city: this.selectedCity },
        //   query: { ...this.$route.query, city: this.selectedCity },
        // });
      }
      else {
        // if not landing page, router go back
        if ( !this.dateModel.length) {
          this.setDateRange(value);
          this.$router.go(-1);
          console.log('error navigating from date picker');
        }
        // no dates selected error displayed
        console.log('error date model length');
      }
     
      
      //this.openGuestPicker();
    },
    openGuestPicker() {
      window.location.hash = '#guests';
    },
    hashChangeHandler() {
      if (location.hash === '#guests') {
        this.showGuestsPicker = true;
      } else {
        this.showGuestsPicker = false;
      }
    },
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.hashChangeHandler, false);
  },
  watch: {
    showGuestsPicker: function() {
      if (this.showGuestsPicker) {
        document.querySelector('html')?.classList.add('modal-open');
        if (!window.location.hash === '#guests') {
          this.$router.push({
            path: this.$route.path,
            hash: '#guests',
          });
        }
      } else {
        document.querySelector('html')?.classList.remove('modal-open');
      }
    },
  }
};
</script>

<style scoped lang="scss">
.divider {
  border-top: 1px solid rgba(229, 231, 235, 1);
  width: 100vw;
}

.outer {
  position: fixed !important;
  width: 100vw !important;
  max-height: 100% !important;
  height: 100vh;
  z-index: 99999;
  left: 0;
  background: rgba(34, 34, 34, 0.6) !important;
  display: flex;
  -webkit-animation: 0.3s ease-out 0s 1 overlay;
  animation: 0.3s ease-out 0s 1 overlay;
  top: 0;
}
@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.inner {
  background: rgb(255, 255, 255) !important;
  position: relative !important;
  width: 100vw !important;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  max-width: 416px !important;
  margin: 0 auto;
  -webkit-animation: 0.2s ease-out 0s 1 slideIn;
  animation: 0.2s ease-out 0s 1 slideIn;
  //align-items: center;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.2s;
  margin-top: auto;
}

.calendar-title {
  font-family: PoppinsSemiBold;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #414145;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 8px;
}
.disclaimer {
  font-family: PoppinsRegular;
  font-size: 14px;
  line-height: 20px;
  color: #414145;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}

@keyframes slideIn {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0);
  }
}
.chevron {
  flex: 0 0 5.3%;
  margin-bottom: auto;
  width: 28px;
  position: absolute;
  height: 28px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  transition: 0.3s;
  margin-left: 1.2rem;
  margin-top: 1.25rem;
}



.chevron:hover > i {
  background: #f1f1f2bd;
}

.chevron > i {
  transition: 0.3s;
  width: 28px;
  color: #28262e;
  height: 28px;
  cursor: pointer;
  background: #f1f1f2;
  border-radius: 1.5rem;
}

.action-bar {
  align-items: flex-end;
  flex-grow: 1;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.close-btn,
.cancel-btn,
.proceed-btn {
  border-radius: 104px !important;
  font-family: MontserratMedium;
  font-style: normal;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: none !important;
}

.proceed-btn {
  width: calc(50% - 48px);
  margin: 16px 24px 32px 24px;

  height: 48px !important;
}
.cancel-btn {
  width: calc(50% - 48px);
  margin: 16px 24px 32px 24px;
  background: white !important;
  height: 48px !important;
}

.close-btn {
  border-radius: 104px !important;
  text-transform: none;
  margin: 0px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  min-width: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  height: 50px !important;
  margin-right: 16px !important;
  margin-left: auto;
  margin-top: 16px !important;
  background: transparent !important;
}

.inner::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.inner::-webkit-scrollbar-thumb {
  background: transparent;
}
</style>



