<template>
  <div class="outer mobile-date-picker">
    <div class="inner">
      <div class="calendar-title">Add Guests</div>
      <div class="disclaimer">Prices are subject to change with guests</div>
      <div class="divider"></div>

      <div class="user-input-container guests-container">
        <span class="guest-select tw-flex">
          <ul class="guest-dropdown">
            <li class="tw-flex tw-flex-row tw-mt-6 tw-mb-8">
              <div class="label">
                Adults<label class="ages tw-ml-1 tw--mt-1">(Ages 17+) </label>
              </div>
              <div class="tw-flex tw-flex-row tw-absolute tw-right-0 tw-mr-6">
                <div
                  class="decrease tw-mr-5"
                  @click="
                    () => {
                      adultsCountToApply -= 1;
                    }
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.91797 7H11.0846"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="count tw-mt-2">{{ adultsCountToApply }}</div>
                <div
                  class="add tw-ml-4"
                  @click="
                    () => {
                      adultsCountToApply += 1;
                    }
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2.91699V11.0837"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.91797 7H11.0846"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </li>
            <li class="tw-flex tw-flex-row tw-mb-8">
              <div class="label">
                Children<label class="ages tw-ml-1 tw--mt-1">(Age 0-17)</label>
              </div>
              <div class="tw-flex tw-flex-row tw-absolute tw-right-0 tw-mr-6">
                <div
                  class="decrease tw-mr-4"
                  @click="
                    () => {
                      childrenCountToApply -= 1;
                    }
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M2.91797 7H11.0846"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div class="count tw-mt-1.5">{{ childrenCountToApply }}</div>
                <div
                  class="add tw-ml-4"
                  @click="
                    () => {
                      childrenCountToApply += 1;
                    }
                  "
                >
                  <svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 2.91699V11.0837"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M2.91797 7H11.0846"
                      stroke="#000000"
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </li>
          </ul>
        </span>
      </div>
      <div v-show="childrenCountToApply > 0">
        <div class="tw-flex tw-justify-center tw-mb-8 children-label">
          Ages of children at checkout
        </div>

        <v-container class="tw-flex tw-justify-center">
          <v-row align="center" class="margin">
            <div
              class="tw-contents"
              v-for="(item, index) in childrenCountToApply"
              :key="item + index"
            >
              <v-col cols="5" sm="3">
                <v-select
                  v-model="selectedAge"
                  :items="childAge"
                  label="Age"
                  filled
                  :rules="ageRules"
                  @change="!this.saveDisabled"
                >
                </v-select>
              </v-col>
            </div>
          </v-row>
        </v-container>
      </div>
      <div class="divider"></div>

      <div class="tw-flex tw-flex-row">
        <div class="cancel-btn" @click="close()">Cancel</div>
        <v-btn
          color="primary"
          class="proceed-btn tw-absolute tw-right-0"
          :disabled="saveDisabled && selectedAge === null"
          @click="
            () => {
              apply();
            }
          "
        >
          Save
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GuestsPicker',
  props: [ 'close' ],
  data() {
    return {
      roomsCountToApply: 1,
      adultsCountToApply: 1,
      childrenCountToApply: 0,
      childrenCounts: [ 0, 1, 2, 3, 4 ],
      ageRules: [ v => parseInt(v) > 0 || 'Is required' ],
      childAge: [ 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17 ],
      saveDisabled: false,
      selectedAge: null,
    };
  },
  async mounted() {
    this.dateModel = [
      this.checkInDate.format('yyyy-MM-DD'),
      this.checkOutDate.format('yyyy-MM-DD'),
    ];
    if (this.childrenCountToApply > 0) {
      this.saveDisabled = true;
    }
  },
  components: {
  },
  methods: {
    apply() {
      this.$router.push({
        path: '/mobilefeed/top/0',
        query: { ...this.$route.query, city: this.selectedCity },
      });
    },
    onChangeSelect(e) {
      this.inputDisabled = e.target.value == 1;
    },
  },
  computed: {
    paxes: function() {
      return new Array(this.childrenCount)
        .fill('')
        .map(() => ({ type: 'CH', age: 0 }));
    },
    childrenAges: function() {
      return new Array(this.childrenCountToApply).fill('');
    },
  },
  watch: {
    childrenCountToApply: {
      handler: function() {
        if (this.childrenCountToApply > 0) {
          this.saveDisabled = true;
        }
        else if (this.childrenCountToApply === 0 ) {
          this.saveDisabled = false;
        }
      },
    },
    selectedAge: {
      handler: function() {
        if (this.selectedAge != null) {
          this.saveDisabled = true;
        }
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
.divider {
  border-top: 1px solid rgba(229, 231, 235, 1);
  width: 100vw;
}

.outer {
  position: fixed !important;
  width: 100vw !important;
  max-height: 100% !important;
  height: 100vh;
  z-index: 99999;
  left: 0;
  background: rgba(34, 34, 34, 0.6) !important;
  display: flex;
  -webkit-animation: 0.3s ease-out 0s 1 overlay;
  animation: 0.3s ease-out 0s 1 overlay;
  top: 0;
}
@keyframes overlay {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.inner {
  background: rgb(255, 255, 255) !important;
  position: relative !important;
  width: 100vw !important;
  display: flex !important;
  flex-direction: column !important;
  box-shadow: rgb(0 0 0 / 28%) 0px 8px 28px !important;
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
  max-width: 416px !important;
  margin: 0 auto;
  -webkit-animation: 0.2s ease-out 0s 1 slideIn;
  animation: 0.2s ease-out 0s 1 slideIn;
  overflow-x: hidden;
  overflow-y: auto;
  transition: 0.2s;
  margin-top: auto;
}

.calendar-title {
  font-family: PoppinsSemiBold;
  font-weight: 600;
  font-size: 24px;
  line-height: 26px;
  color: #414145;
  text-align: center;
  letter-spacing: -0.02em;
  margin-top: 24px;
  margin-bottom: 8px;
}
.disclaimer {
  font-family: PoppinsRegular;
  font-size: 14px;
  line-height: 20px;
  color: #414145;
  text-align: center;
  letter-spacing: -0.02em;
  margin-bottom: 24px;
}

@keyframes slideIn {
  0% {
    transform: translateY(25%);
  }
  100% {
    transform: translateY(0);
  }
}

.label {
  font-family: "SFProTextSemibold";
  font-size: 1rem;
  line-height: 1rem;
  color: $title;
  .ages {
    font-family: "SFProTextRegular";
    font-size: 0.75rem;
    line-height: 1rem;
    color: #7d7d84;
  }
}

.children-label {
  font-family: "SFProTextRegular";
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: $paragraph;
}

.close-btn,
.cancel-btn {
  font-family: "SFProTextMedium";
  margin: 2rem 1.5rem 1rem 3.5rem;
  height: 48px;
  font-size: 0.875rem;
}
.proceed-btn {
  border-radius: 12px !important;
  font-family: "SFProTextSemibold";
  font-style: normal;
  font-size: 0.875rem;
  line-height: 1rem;
  text-align: center;
  letter-spacing: -0.02em;
  box-shadow: none !important;
}

.proceed-btn {
  width: calc(50% - 45px);
  margin: 16px 24px 32px 24px;
  height: 45px !important;
}

.close-btn {
  border-radius: 104px !important;
  text-transform: none;
  margin: 0px;
  padding-left: 40px;
  padding-right: 40px;
  box-shadow: none;
  min-width: 50px !important;
  width: 50px !important;
  min-height: 50px !important;
  height: 50px !important;
  margin-right: 16px !important;
  margin-left: auto;
  margin-top: 16px !important;
  background: transparent !important;
}

.inner::-webkit-scrollbar {
  width: 0; /* Remove scrollbar space */
  background: transparent; /* Optional: just make scrollbar invisible */
}
.inner::-webkit-scrollbar-thumb {
  background: transparent;
}

.add,
.decrease {
  background: #f0f0f0;
  width: 24px;
  height: 24px;
  cursor: pointer;
  border-radius: 50%;
  display: flex;
  svg {
    margin: auto;
  }
}

.age-input-container {
  background: #f8f8f8 !important;
  border: 1px solid #e3e3e4 !important;
  border-radius: 10px;
  max-width: 138px;
  margin-left: 1rem;
  height: 36px;
  & > div:not(.divider) {
    padding: 4px 4px 0 4px;
  }
  .divider {
    background: #747378;
    opacity: 0.2;
    //height: 36px;
    width: 2px;
    margin-top: 14px;
  }
}

.v-menu__content {
  position: unset !important;
}

.drop::v-deep .v-select__slot {
  padding-bottom: 20px !important;
}
.drop::v-deep .v-input__append-inner {
  margin-top: 10px !important;
}

.margin {
  margin-left: 2rem !important;
}
</style>
