<template>
  <div class="tw-visible">
      <overlay-modal ref="modalName">
      <template v-slot:body>
         <div class="tw-mx-auto">
              <SwipeUp class="tw-w-24 tw-h-24" />
            </div>
         <div class="tw-mx-auto">
              <div class="description tw-mt-4">
                Swipe up to discover and book more
              </div>
            </div>
            <div class="tw-mx-auto">
              <div class="description tw-mt-4">
                amazing travel experiences and packages
              </div>
            </div>
      </template>


    </overlay-modal>
    <div class="tw-flex story-container tw-relative">
      <img
        class="tw-w-full tw-h-full tw-object-cover"
        :src="currentPackage.cover_category"
      />
      <div class="playbutton">
        <PlayButton class="tw-w-20 tw-h-20" @click="fullscreen" />
      </div>
    </div>
    <div
      class="
        tw-flex
        tw-flex-row
        tw-rounded-full
        tw-cursor-pointer
        tw-h-8
        tw-ml-2
        tw-mt-6
      "
    >
      <div class="location-pin tw-rounded-l-full">
        <Map-pin class="tw-w-4 tw-h-4" />
      </div>
      <p class="location-padding tw-rounded-r-full">
        {{ city.name }} , {{ city.country }}
      </p>
      <GoldenTicket class="tw-w-12 tw-h-12 tw-absolute tw-right-0 tw-mr-6" />
    </div>

    <div class="tw-ml-2 tw-mt-1 tw-pl-2">
      <div class="package-name tw-pr-20">
        {{
          currentPackage.package_name !== "NULL"
            ? currentPackage.package_name
            : currentPackage.name
        }}
      </div>
      <div class="tw-flex tw-flex-row tw-mt-4 package-avail">
        <UserIcon class="tw-w-4 tw-h-4 tw-mr-2" />
        <div class="tw-mt-1">
          {{ currentPackage.package_number_of_people }} Guests
        </div>
        <Dot class="tw-w-1 tw-h-1 tw-my-auto tw-ml-2 tw-mr-2" />
        <BedIcon class="tw-w-4 tw-h-4 tw-mr-2" />
        <div class="tw-mt-1">
          {{ currentPackage.package_number_of_days }} Nights
        </div>
        <Dot class="tw-w-1 tw-h-1 tw-my-auto tw-ml-2 tw-mr-2" />
        <PlaneIcon class="tw-w-4 tw-h-4 tw-mr-2" />
        <div class="tw-mt-1">
          {{ currentPackage.package_number_of_people }} Return Tickets
        </div>
      </div>
      <div class="tw-flex tw-flex-row hotelinfo tw-mt-3">
        5.0
        <StarIcon class="tw-w-4 tw-h-4 tw-mx-1 tw--mt-1" />
        (Hotel)
      </div>
      <hr class="tw-my-4 tw-mr-6 divider" />
      <div class="tw-flex tw-flex-row influencer tw-mb-4">
        <img
          class="influencer-image tw-w-8 tw-h-8"
          :src="currentPackage.influencer_data.image"
        />
        <InfluencerChecked class="tw-w-4 tw-h-4 tw-mt-5 tw--ml-3" />
        <div class="tw-ml-3">
          <div class="influencer-id tw-mt-3">
            {{ currentPackage.influencer_data.influencer_id }}
          </div>
        </div>
      </div>
      <div class="experience tw-mb-2 tw-mr-12 tw-leading-6">
        {{ currentPackage.name }}
      </div>
      <div class="description tw-mb-6">
        <template v-if="!expandDescription">
          {{ currentPackage.description.substring(0, 50) }}...
          <span
            class="seemore"
            @click="
              () => {
                expandDescription = !expandDescription;
              }
            "
            >See More</span
          >
        </template>
        <template v-else>
          {{ currentPackage.description }}
        </template>
      </div>
      <hr class="tw-my-6 tw-mr-6 divider" />
      <div class="experience">Items on this trip</div>
    </div>
    <div
      class="
        tw-flex tw-flex-row tw-mt-4 tw-w-full tw-font-sfprodisplay-semibold
      "
    >
      <div class="tw-flex tw-flex-col tw-mb-16 tw-w-1/2">
        <div>
          <img
            class="item-container tw-mb-4 tw-h-56 tw-w-full"
            :src="require('@/assets/mobile/packageview/flight.png')"
          />

          <div class="tw-ml-4 tw-mb-8">
            <div class="tw-h-12 tw-leading-4 tw-mr-4">
              FLY TO {{ city.name.toUpperCase() }}
            </div>
            <div class="tw-font-sfprotext-regular tw-mt-4">
              FROM
              <span v-if="flightprice" class="tw-font-sfprodisplay-semibold">
                €{{ flightprice.toFixed(2) }}
              </span>
            </div>
          </div>
        </div>
        <div>
          <img
            class="item-container tw-mb-4 tw-h-56 tw-w-full tw-object-cover"
            :src="currentPackage.activities[0].image"
          />

          <div class="tw-ml-4 tw-mb-8">
            <div class="tw-h-12 tw-leading-4 tw-mr-4">
              {{ currentPackage.activities[0].name.toUpperCase() }}
            </div>
            <div class="tw-font-sfprotext-regular tw-mt-4">
              FROM
              <span class="tw-font-sfprodisplay-semibold">
                €{{ currentPackage.activities[0].price }}</span
              >
            </div>
          </div>
        </div>
      </div>
      <div class="tw-flex tw-flex-col tw-ml-2 tw-w-1/2">
        <div>
          <img
            class="item-container tw-mb-4 tw-h-56 tw-w-full tw-object-cover"
            :src="currentPackage.hotels[0].image"
          />

          <div class="tw-ml-4 tw-mb-8">
            <div class="tw-h-12 tw-leading-4 tw-mr-4">
              {{ currentPackage.hotels[0].name.toUpperCase() }}
            </div>
            <div class="tw-font-sfprotext-regular tw-mt-4">
              FROM
              <span class="tw-font-sfprodisplay-semibold">
                €{{ currentPackage.hotels[0].price }}</span
              >
            </div>
          </div>
        </div>
        <div>
          <img
            class="item-container tw-mb-4 tw-h-56 tw-w-full tw-object-cover"
            :src="currentPackage.restaurants[0].image"
          />

          <div class="tw-ml-4 tw-mb-8">
            <div class="tw-h-12 tw-leading-4 tw-mr-4">
              {{ currentPackage.restaurants[0].name.toUpperCase() }}
            </div>
            <div class="tw-font-sfprotext-regular tw-mt-4">
              FROM
              <span class="tw-font-sfprodisplay-semibold">
                €{{ currentPackage.restaurants[0].price }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer
      :experience="currentPackage"
      :loading="loadingFlight"
      :price="totalPrice"
      :checkInDate="checkInDate"
      :checkOutDate="checkOutDate"
      :setDateRange="setDateRange"
      class="tw-bottom-0 sticky tw-w-full tw-h-12"
    />
  </div>
</template>

<script>
import MapPin from '@/assets/mobile/blackmappin.svg';
import UserIcon from '@/assets/mobile/users.svg';
import BedIcon from '@/assets/mobile/hotels.svg';
import PlaneIcon from '@/assets/mobile/plane.svg';
import StarIcon from '@/assets/mobile/star.svg';
import GoldenTicket from '@/assets/mobile/goldenticket.svg';
import Dot from '@/assets/mobile/dot.svg';
import InfluencerChecked from '@/assets/exploreinfluencers/influencer-checked.svg';
import PlayButton from '@/assets/mobile/packageview/play-circle.svg';
import SwipeUp from '@/assets/mobile/packageview/swipeup.svg';
import Footer from '../StickyFooter.vue';
import { mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import OverlayModal from './OverlayModal.vue';

export default {
  name: 'MobilePackageView',
  data() {
    return {
      currentPackage: null,
      cities: [],
      expandDescription: false,
      flight: null,
      checkInDate: moment().add(30, 'days'),
      checkOutDate: moment().add(33, 'days'),
      flightprice: null,
      show: true,
    };
  },
  components: {
    MapPin,
    UserIcon,
    Dot,
    BedIcon,
    PlaneIcon,
    StarIcon,
    GoldenTicket,
    InfluencerChecked,
    Footer,
    PlayButton,
    SwipeUp,
    OverlayModal
  },
  computed: {
    ...mapGetters([ 'experience', 'PackageExperiences' ]),
    loadingFlight: {
      get() {
        return typeof this.flight !== 'object';
      },
    },
    city: {
      get() {
        try {
          return this.cities.data[this.currentPackage.city_id];
        } catch (err) {
          return null;
        }
      },
    },
    storyItems() {
      return this.currentPackage.story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image',
        };
      });
    },
    totalPrice() {
      return (
        0 +
        (this.currentPackage.restaurants[0].price || 0) +
        (this.flightprice || 0) +
        (this.currentPackage.hotels[0].price || 0) +
        (this.currentPackage.activities[0].price || 0)
      );
    },
  },
  async mounted() {
    this.cities = await axios.get(
      'https://travelo-91156-default-rtdb.europe-west1.firebasedatabase.app/destination_cities.json'
    );
    !this.PackageExperiences.length && (await this.loadPackageExperiences());
    await this.loadExperience(this.$route.params.experienceId);
    this.currentPackage = this.experience;
    this.loadFlights();
    // this.currentPackage = this.PackageExperiences[0];
    // Promise.all([
    //   this.obtainLocation(),
    //   this.experience
    //     ? Promise.resolve(this.experience)
    //     : this.$store.dispatch('loadExperience', experiences[0]),
    // ]).then(() => {
    //   this.loadFlights();
    // });
  },
  methods: {
    ...mapActions([
      'loadAvailabilityHotels',
      'loadExperiences',
      'loadExperience',
      'loadPackageExperiences',
    ]),
    setDateRange(arr) {
      this.checkInDate =
        arr.length >= 1 ? moment(arr[0], 'yyyy-MM-DD') : undefined;
      this.checkOutDate =
        arr.length >= 2 ? moment(arr[1], 'yyyy-MM-DD') : undefined;
      this.loadFlights();
    },
    overlayToggle() {
      this.overlay = !this.overlay;
    },
    ...mapActions('location', [ 'obtainLocation' ]),
    fullscreen() {
      this.$router.push({
        name: 'MobileStory',
        params: {
          inspirationId: this.currentPackage.exp_id,
        },
      });
    },
    async loadFlights() {
      this.flight = -1;
      const airportCodes = this.$store.getters['location/airports']?.map(
        airport => airport.code
      ) || [ 'BER' ];
      for (let index = 0; index < airportCodes.length; index++) {
        const departureAirport = airportCodes[index];
        const destinationAirport = this.currentPackage.flight_destination;
        const response = await axios.get(
          // 'https://flights.dev.go-travelo.com/api/search',
          'https://api.tequila.kiwi.com/v2/search',
          {
            headers: {
              apikey: 'ZrsW4-6pv0JOWcXtplHvWs81EoU_HEJ5',
            },
            params: {
              fly_from: departureAirport,
              fly_to: destinationAirport,
              // departure_date: this.checkInDate.format('YYYY-MM-DD'),
              date_from: this.checkInDate.format('YYYY-MM-DD'),
              // return_date: this.checkOutDate.format('YYYY-MM-DD'),
              date_to: this.checkOutDate.format('YYYY-MM-DD'),
              max_stopovers: 10,
              max_fly_duration: 50,
              // limit: 1,
            }
          }
        );

        if (response.data.length > 0) {
          this.flight = response.data[0];
          break;
        }
      }
      this.flightprice = this.flight?.price?.[0]?.price;
    },
  },
};
</script>

<style scoped lang="scss">
@import "~@/assets/styles/variables.scss";
@import "~@/assets/styles/grid.scss";


.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 400px;
  height: 200px;
  background-color: red;
}
.action {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0);
  opacity: 80%;
}

.bg {
  background-color: rgba(0, 0, 0);
  opacity: 80%;
}

.story-container {
  width: 100%;
  height: 80vw;
  background-color: grey;
  object-fit: cover;
}

.playbutton {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.location-pin {
  padding-top: 6px;
  padding-left: 8px;
}

.location-padding {
  color: #111216;
  padding: 10px 8px;
  padding-right: 12px;
  font-size: 3vw;
  font-family: "SFProTextMedium";
}

.package-name {
  font-family: "SFProDisplayBold";
  font-size: 2rem;
  line-height: 2rem;
}

.package-avail {
  font-family: "SFProTextMedium";
  font-size: 0.7rem;
}

.hotelinfo {
  color: #414145;
  font-family: "SFProTextMedium";
  font-size: 0.7rem;
}

.divider {
  background: #f0f0f0;
  width: unset !important;
}

.influencer {
  font-family: "SFProTextSemibold";
  .influencer-image {
    border-radius: 50%;
    object-fit: cover;
    user-select: none;
  }
  .influencer-id {
    font-size: 2.9vw;
  }
}

.experience {
  font-family: "SFProDisplaySemibold";
  font-size: 1.5rem;
  letter-spacing: 0.03rem;
}

.description {
  font-family: "SFProTextRegular";
  line-height: 1.5rem;
}

.seemore {
  font-family: "SFProTextSemibold";
}

.item-container {
  //height: 322px;
  width: 100%;
  background-color: grey;
  object-fit: cover;
}

.sticky {
  padding: 1em;
  position: sticky;
  z-index: 50;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.description {
  font-family: "SFProTextRegular";
  font-size: 1rem;
  line-height: 1rem;
  color: white;
  letter-spacing: -0.03rem;
}
</style>
