<template>
  <div class="footer-container">
    <template v-if="!loading">
      <div class="tw-flex tw-pr-4 tw-mb-8">
        <div class="tw-mx-0 price tw-flex-1 tw-mt-3">
          <span> €{{ price }} </span>
          <span class="light"> / Total Package </span>
          <div class="tw-mt-2 dates" @click="openCalendar">
            {{ format(checkInDate) }} - {{ format(checkOutDate) }}
          </div>
        </div>
        <div class="" @click="HandleToSteps">
          <v-btn class="reservebutton price"> Reserve Trip </v-btn>
        </div>
      </div>
    </template>
    <div class="loading" v-else><div>loading...</div></div>
    <DateRangePicker
      :close="
        () => {
          showDateRangePicker = false;
          $router.go(-1);
        }
      "
      :checkInDate="checkInDate"
      :checkOutDate="checkOutDate"
      :setDateRange="setDateRange"
      v-if="showDateRangePicker"
    />
  </div>
</template>

<script>
import DateRangePicker from '../mobilePages/Components/DateRangePicker.vue';
import { mapActions } from 'vuex';
export default {
  name: 'StickyFooter',
  data() {
    return {
      showDateRangePicker: false,
      showGuestsPicker: false,
    };
  },
  props: [ 'price', 'loading', 'checkInDate', 'checkOutDate', 'setDateRange', 'experience' ],
  components: { DateRangePicker },
  mounted() {
    window.addEventListener('hashchange', this.hashChangeHandler, false);
  },
  beforeDestroy() {
    window.removeEventListener('hashchange', this.hashChangeHandler, false);
  },
  computed: {},
  methods: {
    format(date) {
      return date.format('ddd MMM D');
    },
    openCalendar() {
      window.location.hash = '#calendar';
    },
    hashChangeHandler() {
      if (location.hash === '#calendar') {
        this.showDateRangePicker = true;
      } else {
        this.showDateRangePicker = false;
      }
    },
    ...mapActions([ 'addPackageCityExperienceId' ]),
    HandleToSteps() {
      this.addPackageCityExperienceId({ id: this.experience.city_id });
      //this.$router.push({ name: 'MobilePackageSteps', params: { experienceId: this.experience.exp_id } });
      this.$router.push({ name: 'MobileFlights', params: { experienceId: this.experience.exp_id }, query: this.$route.query });
    }
  },
  watch: {
    showDateRangePicker: function() {
      if (this.showDateRangePicker) {
        document.querySelector('html')?.classList.add('modal-open');
        if (!window.location.hash === '#calendar') {
          this.$router.push({
            path: this.$route.path,
            hash: '#calendar',
          });
        }
      } else {
        document.querySelector('html')?.classList.remove('modal-open');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '~@/assets/styles/variables.scss';
@import '~@/assets/styles/grid.scss';
.footer-container {
  background-color: white;
  color: #111216;
  height: 100px;
  .price {
    font-family: 'SFProTextSemibold';
    font-size: 1.25rem;
  }
  .dates {
    font-family: 'SFProTextMedium';
    letter-spacing: -0.03rem;
    text-decoration: underline;
    font-size: 1rem;
  }
  .light {
    font-family: 'SFProTextRegular';
    font-size: 0.95rem;
  }
  .reservebutton {
    background-color: $primary;
    color: white;
    letter-spacing: -0.03rem;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: -0.02em;
    box-shadow: none;
  }
  .v-btn {
    width: 8rem !important;
    height: 3rem !important;
  }
}

.loading {
  margin: auto;
  height: 100px;
  align-items: center;
  justify-content: center;
  div {
    text-align: center;
    margin: auto;
    margin-top: 35px;
  }
}
</style>
