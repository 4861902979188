<template>
  <transition name="fade">
    <div class="modal" v-if="show">
      <div class="modal__dialog" v-on:click="closeModal()">
        <div class="modal__body">
          <slot name="body" />
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'OverlayModal',
  data() {
    return {
      show: true,
    };
  },
  methods: {
    closeModal() {
      this.show = false;
      document.querySelector('body').classList.remove('overflow-hidden');
    },
    openModal() {
      this.show = true;
      document.querySelector('body').classList.add('overflow-hidden');
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  overflow-x: hidden;
  overflow-y: auto;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 9;
  &__dialog {
    background-color: rgba(0, 0, 0, 0.8);
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
    justify-content: center;
    overflow: auto;
  }
  &__body {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
